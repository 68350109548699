import React, { lazy } from "react";
import { reducerType } from "redux/reducers";

const GridFullHeight = lazy(() => import("components/GridFullHeight"));
const GridFormContainer = lazy(() => import("components/GridFormContainer"));

const mapStateToProps = (state: reducerType) => {
  return {};
};

const AccountDeletionPolicy = React.memo(() => {
  return (
    <GridFullHeight container>
      <GridFormContainer item xs={12} sm={12} md={12}>
        <div style={{ padding: 12 }}>
          <h2>Account Deletion Policy</h2>
          <p>
            Welcome to <b>Shelter App</b>. We respect your privacy, and if you
            wish to delete your account, please follow the steps below.
          </p>

          <h3>🔹 Steps to Request Account Deletion</h3>
          <ol>
            <li>
              Send an email to <b>ShelterAppInfo@gmail.com</b> with the subject:{" "}
              <b>"Request for Account Deletion"</b>.
            </li>
            <li>
              In the email, include your registered email addresss and reason
              for deletion.
            </li>
            <li>
              Our customer support team will contact you within 3 business days
              to confirm your request.
            </li>
            <li>
              If you confirm the request, your account will be permanently
              deleted within 3 days.
            </li>
            <li>
              After deletion, you will receive a confirmation email that your
              account has been successfully removed.
            </li>
          </ol>

          <h3>🔹 Data That Will Be Deleted</h3>
          <p>
            Once your account is deleted, the following data will be permanently
            removed:
          </p>
          <ul>
            <li>Personal Information (Name, Email, Profile Picture, etc.)</li>
            <li>All shelter requests and activity history</li>
            <li>Saved preferences and settings</li>
          </ul>

          <h3>🔹 Data That May Be Retained</h3>
          <p>
            Some data may be retained for legal, security, or operational
            reasons:
          </p>
          <ul>
            <li>
              Transaction records for financial compliance (if applicable)
            </li>
            <li>Abuse reports or legal investigation data</li>
            <li>Anonymous statistical data (non-identifiable)</li>
          </ul>
          <p>
            All retained data is securely stored and deleted after the legally
            required retention period.
          </p>

          <h3>🔹 Contact Us</h3>
          <p>
            If you have any issues regarding account deletion, please contact us
            at:
            <strong> ShelterAppInfo@gmail.com</strong>
          </p>
        </div>
      </GridFormContainer>
    </GridFullHeight>
  );
});

export default AccountDeletionPolicy;
